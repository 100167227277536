import { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import {
  BorderProps,
  LayoutProps,
  MarginProps,
  PaddingProps,
} from 'styled-system';

import { Link as RouterLink } from 'components/RouterLink';
import { SmallTile } from 'components/Tile';
import { colors, transition } from 'theme/theme';

export const OverlayRouterLink = styled(RouterLink)`
  &,
  &:active,
  &:visited {
    color: inherit;
    text-decoration: none;
  }

  &::after {
    z-index: 0;
    position: absolute;
    content: '';
    inset: 0;
  }
`;
export const HoverEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(
      to left,
      ${colors.lemon},
      ${colors.brandYellow}
    );
    transition: all ${transition};
  }
`;

type Props = {
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: MouseEventHandler;
};

export const SmallTileWithHoverEffect = styled(
  ({ children, disabled, ...props }: Props) => (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SmallTile {...props}>
      <HoverEffect />
      {children}
    </SmallTile>
  ),
)<MarginProps & PaddingProps & LayoutProps & BorderProps>`
  overflow: visible;
  ${(props) =>
    !props.disabled
      ? `
  cursor: pointer;
  ${HoverEffect} {
    &::before {
      transform: translateY(-100%);
    }
  }
  &:hover,
  &:focus {
    ${HoverEffect} {
      &::before {
        transform: translateY(0);
      }
    }
  }`
      : `
    background: transparent;
    box-shadow: 0 0 0 1px ${colors.selectionGrey};
  `}
`;
