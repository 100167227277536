import { Location } from 'react-router-dom';
import styled from 'styled-components';

import { Link as ReactRouterLink } from 'components/RouterLink';
import { HoverEffect } from 'components/Tile/SmallTileWithHoverEffect';
import { ApiVolopImportedType } from 'modules/listing/api/volop/types/ApiVolopImportedType';
import {
  ListingLink,
  ListingRouterLink,
} from 'modules/listing/components/ListingLink';
import { getImportedListingUrl } from 'modules/search/helpers/getImportedListingUrl';
import { colors } from 'theme/theme';

export const SearchTileLinkOverlay = styled(
  ({
    url,
    orgId,
    orgUrl,
    listingType,
    trackClick,
    source,
    location,
    ...props
  }: {
    disableHoverEffect?: boolean;
    orgId?: string | null;
    orgUrl?: string | Record<UserLocale, string> | null;
    url: string | Record<UserLocale, string>;
    listingType?: string | null;
    trackClick?: () => void;
    location: Location;
    source?: ApiVolopImportedType | 'IDEALIST';
  }) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-nested-ternary
    typeof url === 'object' ? (
      <ReactRouterLink
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        to={url[CURRENT_LOCALE]}
        onClick={() => {
          if (trackClick) trackClick();
        }}
        tabIndex={-1}
      />
    ) : listingType === 'IMPORTED' ? (
      /* eslint-disable jsx-a11y/anchor-has-content */
      <a
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        href={getImportedListingUrl(url, source)}
        onClick={() => {
          if (trackClick) trackClick();
        }}
        target="_blank"
        rel="noopener noreferrer"
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
        tabIndex="-1"
      />
    ) : (
      <a
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        href={url}
        onClick={() => {
          if (trackClick) trackClick();
        }}
        tabIndex={-1}
      />
      /* eslint-enable jsx-a11y/anchor-has-content */
    ),
)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;

  &:focus {
    outline: 0;
  }

  & + * {
    ${HoverEffect} {
      &::before {
        transform: translateY(-100%);
      }
    }
  }

  &:hover,
  &:focus {
    & + * {
      ${HoverEffect} {
        &::before {
          transform: ${(props) =>
            props.disableHoverEffect ? 'translateY(-100%)' : 'translateY(0)'};
        }
      }
      ${ListingLink}, ${ListingRouterLink} {
        color: ${colors.brandBlue};
      }
    }
  }
`;
