import { ReactNode } from 'react';

import { LegacyButton as Button } from 'components/Button/LegacyButton';
import { SlimSearchTile } from 'components/SlimSearchTile';
import { SmallTileWithHoverEffect } from 'components/Tile/SmallTileWithHoverEffect';

type SectionSearchTileProps = {
  layout: 'SLIM' | 'REGULAR';
  children: ReactNode;
};

export function SectionSearchTile({
  layout,
  children,
}: SectionSearchTileProps) {
  if (layout === 'REGULAR') {
    return (
      <SmallTileWithHoverEffect
        // @ts-expect-error TS(2769): No overload matches this call.
        component={Button}
        display="block"
        p="20px !important"
        width="100%"
        height={[310, null, 330]}
      >
        {children}
      </SmallTileWithHoverEffect>
    );
  }

  return (
    <SlimSearchTile
      component={Button}
      display="block"
      px="36px !important"
      py="20px !important"
      width="100%"
      height={200}
    >
      {children}
    </SlimSearchTile>
  );
}
