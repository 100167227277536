import styled from 'styled-components';

import { Link as ReactRouterLink } from 'components/RouterLink';
import { HoverEffect } from 'components/Tile/SmallTileWithHoverEffect';
import { colors, transition } from 'theme/theme';

const listingLinkStyles = `
  cursor: pointer;
  transition: all ${transition};
  &:hover,
  &:focus {
    color: ${colors.brandBlue};
    text-decoration: none;
    & + ${HoverEffect} {
      &::before {
        transform: translateY(0);
      }
    }
  }
`;

const Link = styled.a`
  &,
  &:active,
  &:visited {
    color: inherit;
    text-decoration: none;
    position: relative;
  }

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

export const ListingLink = styled(Link)`
  ${listingLinkStyles}
`;

const RouterLink = styled(ReactRouterLink)`
  &,
  &:active,
  &:visited {
    color: inherit;
    text-decoration: none;
    position: relative;
  }

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

export const ListingRouterLink = styled(RouterLink)`
  ${listingLinkStyles}
`;
