import styled from 'styled-components';

import { SmallTile } from 'components/Tile';
import { colors, transition } from 'theme/theme';

export const SlimSearchTile = styled(SmallTile)`
  overflow: visible;
  cursor: pointer;
  border-top: 4px solid ${colors.brandBlue};
  transition: all ${transition};

  &:hover,
  &:focus {
    border-color: ${colors.brandGreen};
  }
`;
